// Enter all your detials in this file
// Logo images
import logogradient from "./assets/signature.png";
import logo from "./assets/signature.png";
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import django from "./assets/techstack/django.png";
import git from "./assets/techstack/git.png";
import github from "./assets/techstack/github.png";
import java from "./assets/techstack/java.png";
import postman from "./assets/techstack/postman.png";
import python from "./assets/techstack/python.png";
import pytorch from "./assets/techstack/pytorch.png";
import springboot from "./assets/techstack/springboot.png";
import vscode from "./assets/techstack/vscode.png";

// Porject Images
import projectImage1 from "./assets/projects/project1.jpg";
import projectImage2 from "./assets/projects/project2.jpg";
import projectImage3 from "./assets/projects/project3.jpg";
import projectImage4 from "./assets/projects/project4.jpg";
import projectImage5 from "./assets/projects/project5.jpg";
import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Peiyuan Li",
  tagline: "", //short intro
  img: profile,
  about: `I am currently a Master's student in Computer Science at Brown University. 
  Prior to this, I completed my Bachelor's degree at the University of Wisconsin-Madison, 
  double majoring in Computer Science and Data Science. I have industry experience from my 
  internships at Airbnb, where I contributed to backend development and optimization. 
  I'm passionate about software development and machine learning. 
  Welcome to my space!`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/peiyuan-li-257958277/",
  github: "https://github.com/pli233",
  twitter: "https://twitter.com/",
  resume: "../public/latest_resume.pdf",
  instagram: "https://www.instagram.com/",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Software Engineer Intern",
    Company: `Airbnb, Inc.`,
    Location: "Remote in US",
    Type: "Internship",
    Duration: "May 2023 – Aug 2023",
  },
  {
    Position: "Software Development Intern",
    Company: `Shenzhen Wande Software Co., Ltd.`,
    Location: "Shenzhen, China",
    Type: "Internship",
    Duration: "May 2021 – Sep 2021",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Major: "Computer Science",
    Degree: "Master of Science",
    University: "Brown University",
    Location: "Providence, Rhode Island",
    Duration: "Sep 2024 - Present",
  },
  {
    Major: "Computer Science & Data Science",
    Degree: "Bachelor of Science",
    University: "University of Wisconsin-Madison",
    Location: "Madison, Wisconsin",
    Duration: "Sep 2020 - May 2024",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html,
  css,
  vscode,
  git,
  github,
  django,
  java,
  python,
  pytorch,
  postman,
  springboot,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Project title 1",
    image: projectImage1,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 2",
    image: projectImage2,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 3",
    image: projectImage3,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 4",
    image: projectImage4,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 5",
    image: projectImage5,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 6",
    image: projectImage6,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "peiyuan_li[at]brown.edu",
};
