import React from "react";
import { techStackDetails } from "../Details";

function Technologies() {
  const {
    html,
    css,
    vscode,
    git,
    github,
    django,
    java,
    python,
    pytorch,
    springboot,
  } = techStackDetails;
  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
        <p className="text-content py-2 lg:max-w-3xl">
          Technologies I've been working with recently
        </p>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img className="w-16 h-16" src={html} title="CSS" alt="HTML Logo" />
        <img className="w-16 h-16" src={css} title="CSS" alt="CSS Logo" />
        <img className="w-16 h-16" src={git} title="Git" alt="Git Logo" />
        <img className="w-16 h-16" src={django} title="Django" alt="Django Logo" />
        <img className="w-16 h-16" src={java} title="Java" alt="Java Logo" />
        <img className="w-16 h-16" src={python} title="Python" alt="Python Logo" />
        
        <img className="w-80 h-16" src={pytorch} title="PyTorch" alt="PyTorch Logo" />
        <img className="w-80 h-16" src={springboot} title="Spring Boot" alt="Spring Boot Logo" />
      </section>


      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img className="w-16 h-16" src={vscode} title="Visual Studio Code" alt="VSCode Logo" />
        <img className="w-16 h-16" src={github} title="Github" alt="Github Logo" />
      </section>
    </main>
  );
}

export default Technologies;
